import {useCallback, useEffect, useRef, useState} from "react";

const App = () => {
    const [coins, setCoins] = useState({}); // Дані монет {symbol: {initialVolume, currentVolume, percentChange, absoluteChange, addedAt}}
    const [significantChanges, setSignificantChanges] = useState([]); // Монети зі зміною > 5%
    const [hiddenPairs, setHiddenPairs] = useState([]); // Список прихованих пар
    const initialVolumesRef = useRef({}); // Зберігання початкового обсягу для кожної монети
    const socketRef = useRef(null); // Посилання на WebSocket
    const reconnectIntervalRef = useRef(null); // Таймер для реконекту

    const connectWebSocket = () => {
        socketRef.current = new WebSocket('wss://wbs.mexc.com/ws');

        socketRef.current.onopen = () => {
            clearTimeout(reconnectIntervalRef.current);
            const subscriptionRequest = {
                method: "SUBSCRIPTION",
                params: ["spot@public.miniTickers.v3.api@UTC+8"],
            };
            socketRef.current.send(JSON.stringify(subscriptionRequest));
        };

        socketRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.d) {
                const updatedCoins = processCoinData(data.d);
                updateCoinsState(updatedCoins);
            }
        };

        socketRef.current.onclose = () => {
            console.warn("WebSocket закрито. Спроба реконекту...");
            attemptReconnect();
        };

        socketRef.current.onerror = (error) => {
            console.error("WebSocket помилка:", error);
            socketRef.current.close();
        };
    };

    const attemptReconnect = () => {
        reconnectIntervalRef.current = setTimeout(() => {
            console.log("Спроба повторного підключення...");
            connectWebSocket();
        }, 5000);
    };

    const processCoinData = (data) => {
        const newCoins = {};

        data.forEach((coin) => {
            const { s: symbol, v: volumeValue } = coin;

            if (!symbol.endsWith("USDT")) return; // Пропускаємо всі пари, що не закінчуються на USDT

            const currentVolume = parseFloat(volumeValue);

            // Виключаємо пари з від'ємним об'ємом або приховані пари
            if (currentVolume < 0 || hiddenPairs.includes(symbol)) return;

            if (!initialVolumesRef.current[symbol]) {
                initialVolumesRef.current[symbol] = currentVolume;
                newCoins[symbol] = {
                    initialVolume: currentVolume,
                    currentVolume,
                    percentChange: 0,
                    absoluteChange: 0,
                    addedAt: Date.now(), // Встановлюємо час додавання тільки при першій появі монети
                };
                return;
            }

            const initialVolume = initialVolumesRef.current[symbol];
            const absoluteChange = currentVolume - initialVolume;

            // Якщо зміна об'єму < 500, оновлюємо об'єм, але не додаємо в список
            if (Math.abs(absoluteChange) < 500) {
                initialVolumesRef.current[symbol] = currentVolume;
                return;
            }

            const percentChange = (absoluteChange / initialVolume) * 100;

            newCoins[symbol] = {
                initialVolume,
                currentVolume,
                percentChange,
                absoluteChange,
            };
        });

        return newCoins;
    };

    const updateCoinsState = useCallback(
        (updatedCoins) => {
            setCoins((prevCoins) => {
                const newState = { ...prevCoins };

                // Оновлюємо або додаємо монети
                Object.entries(updatedCoins).forEach(([symbol, data]) => {
                    if (!hiddenPairs.includes(symbol)) {
                        newState[symbol] = {
                            ...data,
                            addedAt: prevCoins[symbol]?.addedAt || Date.now(), // Якщо вже є, залишаємо, інакше додаємо новий час
                        };
                    }
                });

                // Формуємо значні зміни, виключаючи приховані пари
                const significant = Object.entries(newState).reduce((acc, [symbol, data]) => {
                    const isSignificant =
                        Math.abs(data.percentChange) > 5 &&
                        data.absoluteChange >= 0 &&
                        !hiddenPairs.includes(symbol); // Виключаємо приховані пари

                    if (isSignificant) {
                        acc[symbol] = { ...data, addedAt: newState[symbol].addedAt };
                    }

                    return acc;
                }, {});

                // Оновлюємо значні зміни (таблиця)
                const changes = Object.entries(significant).sort(
                    ([, a], [, b]) => b.addedAt - a.addedAt // Сортуємо за часом додавання (нові зверху)
                );

                setSignificantChanges(changes);

                return newState;
            });
        },
        [hiddenPairs]
    );

    const toggleHiddenPair = (symbol) => {
        const updatedHiddenPairs = [...hiddenPairs, symbol]; // Додаємо пару в список прихованих
        setHiddenPairs(updatedHiddenPairs);
        localStorage.setItem("hiddenPairs", JSON.stringify(updatedHiddenPairs));

        // Видаляємо приховані пари з таблиці та стану
        setCoins((prevCoins) => {
            const updatedCoins = { ...prevCoins };
            delete updatedCoins[symbol];
            return updatedCoins;
        });

        setSignificantChanges((prevChanges) =>
            prevChanges.filter(([key]) => key !== symbol) // Видаляємо приховану пару з таблиці
        );
    };

    const formatTimeAgo = (timestamp) => {
        const seconds = Math.floor((Date.now() - timestamp) / 1000);

        if (seconds < 60) return `${seconds}s`;
        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) return `${minutes}m ${seconds % 60}s`;
        const hours = Math.floor(minutes / 60);
        return `${hours}h ${minutes % 60}m`;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSignificantChanges((changes) => [...changes]); // Форсуємо оновлення стану
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatNumber = (num) => {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(2) + "B";
        } else if (num >= 1e6) {
            return (num / 1e6).toFixed(2) + "M";
        } else if (num >= 1e3) {
            return (num / 1e3).toFixed(2) + "k";
        }
        return num.toFixed(2);
    };

    useEffect(() => {
        const savedHiddenPairs = JSON.parse(localStorage.getItem("hiddenPairs")) || [];
        setHiddenPairs(savedHiddenPairs);

        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            clearTimeout(reconnectIntervalRef.current);
        };
    }, []);

    const formatLink = (symbol) => {
        const formattedSymbol = symbol.replace(/(USDT|BTC|ETH|BNB|SOL)$/, '_$1');
        return `https://www.mexc.com/uk-UA/exchange/${formattedSymbol}`;
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Volume Change</h1>
            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Symbol</th>
                    <th>Initial Volume</th>
                    <th>Current Volume</th>
                    <th>Change (%)</th>
                    <th>Time Added</th>
                    <th>Hide</th>
                </tr>
                </thead>
                <tbody>
                {significantChanges.length > 0 ? (
                    significantChanges.map(([symbol, data], index) => (
                        <tr key={symbol}>
                            <td>{index + 1}</td>
                            <td>
                                <a href={formatLink(symbol)} target="_blank" rel="noopener noreferrer">
                                    {symbol}
                                </a>
                            </td>
                            <td>{formatNumber(data.initialVolume)}</td>
                            <td>
                                {formatNumber(data.currentVolume)}{" "}
                                <strong className={data.absoluteChange >= 0 ? "text-success" : "text-danger"}>
                                    ({data.absoluteChange >= 0 ? "+" : ""}{formatNumber(data.absoluteChange)})
                                </strong>
                            </td>
                            <td>{data.percentChange.toFixed(2)}%</td>
                            <td>{formatTimeAgo(data.addedAt)}</td>
                            <td>
                                <button className="btn btn-sm btn-danger" onClick={() => toggleHiddenPair(symbol)}>
                                    Hide
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr key={`placeholder-0`}>
                        <td colSpan="7" style={{ height: "50px" }} />
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default App;
